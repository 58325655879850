/* eslint-disable no-shadow */
import {
  filter, first, flatMap, pluck, switchMap,
} from 'rxjs/operators';
import { combineEpics, ofType, } from 'redux-observable';
import { of, } from 'rxjs';
import {
  compose,
  equals,
  find,
  isEmpty,
  last,
  lensProp,
  map,
  omit,
  over,
  propEq,
  symmetricDifference,
  length,
  uniq,
} from 'ramda';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import {
  CURRENT_BALANCE, GAME_RESULT, INITIAL_DATA, NO_MORE_BETS, PLACE_YOUR_BETS, CARD_MESSAGE, SPLIT_CARDS, LAST_BETS,
} from '@ezugi/constants';

import { GAME_RESULT as GAMERESULTS, WON_SIDE_BETS, } from './constants';

import { getGameParams, } from '../../../util/epicsUtils';
import seatActions from '../../actions/seat';
import betActions from '../../actions/bets';
import decisionActions from '../../actions/decision';
import splitHandActions from '../../actions/splitHand';
import insuranceActions from '../../actions/insurance';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';
import handleAutoSplit from './handleAutoSplit';
import handleWonBets from './handleWonBets';

import { pluckBJ, } from '../auth/utils';
import { LEAVE_SEAT, SEAT_TAKEN, } from '../seats/constants';
import {
  betsHistorySelector,
  currentBetsSelector,
  nonDealerSeatsSelector,
  playerSeatsIdsSelector,
  playerSeatsSelector,
  userCachedSeatsSelector,
  userSeatsIdsSelector,
} from '../../selectors';
import playersActions from '../../actions/players';
import { getTotalBet, } from '../bets/utils';
import { BET_TYPES, START_CALL_BETS, } from '../../../constants';

const {
  socketActions: { socket, },
  roundActions: { round, },
  authActions,
  configActions,
  notificationActions: { notification, },
} = bootstrapActions;

const { insurance, } = insuranceActions;
const { seat, temporaryUnclickable, } = seatActions;
const { bet, history, } = betActions;
const { decision, } = decisionActions;
const { splitHand, } = splitHandActions;
const { seats, userSeats, } = playersActions;
const { MAIN_BET, INSURANCE_BET, } = BET_TYPES;

function initialDataEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap(handleInitialData),
  );
}

function bjConfigEpic(action$, store$) {
  return action$.pipe(
    ofType(authActions.auth.success),
    switchMap(() => store$.pipe(
      first(),
      pluck('config', 'blackjack'),
    )),
    flatMap((blackjack) => of(
      configActions.config.update({
        bj: pluckBJ(blackjack),
      }),
    )),
  );
}

function socketRequestsEpic(action$) {
  return action$.pipe(
    ofType(seat.request, bet.request, insurance.send, decision.send, splitHand.send),
    pluck('payload'),
    flatMap((params) => of(socket.send(params))),
  );
}

function handleRoundStatusEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ PLACE_YOUR_BETS, NO_MORE_BETS, ].includes(MessageType)),
    flatMap(({ MessageType, }) => {
      const state = store$.value;
      return of(
        round.set({
          canSendNormalBetRequest: [ PLACE_YOUR_BETS, LAST_BETS, ].includes(MessageType),
          canPlaceBets: [ PLACE_YOUR_BETS, ].includes(MessageType),
        }),
        ...(MessageType === PLACE_YOUR_BETS ? [ seats.clean(), userSeats.clean(), ] : []),
        ...(MessageType === NO_MORE_BETS ? [ userSeats.cache(userSeatsIdsSelector(state)), ] : []),
      );
    }),
  );
}

function handleSeatTakenEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ SEAT_TAKEN, ].includes(MessageType)),
    flatMap((socketMessage) => {
      const state = store$.value;
      const {
        SeatId,
        PlayerOnSeat,
        playerOnSeatId,
        AllowsBetBehind,
      } = socketMessage;

      const isCurrentUserSeat = userSeatsIdsSelector(state).includes(SeatId);
      const currentSeats = [ SeatId, ].concat(playerSeatsIdsSelector(state));
      const cachedUserSeats = userCachedSeatsSelector(state);
      const equalsIgnoreOrder = compose(isEmpty, symmetricDifference);
      const actions = [
        seats.update({
          seatId: SeatId,
          nickname: PlayerOnSeat,
          playerId: playerOnSeatId,
          allowsBetBehind: AllowsBetBehind,
          reservedSeat: false,
        }),
        temporaryUnclickable.reset({ unclickable: false, }),
      ];
      if (isCurrentUserSeat && !equalsIgnoreOrder(cachedUserSeats, currentSeats)) {
        actions.push(userSeats.clean());
      }
      actions.push(userSeats.cache(userSeatsIdsSelector(state)));
      return of(...actions);
    })
  );
}

function handleSeatLeaveEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ LEAVE_SEAT, ].includes(MessageType)),
    flatMap(({ SeatId, }) => {
      /**
       * Remove seat from bets and bets history
       */
      const state = store$.value;
      const currentHistory = betsHistorySelector(state);

      const newHistory = uniq(currentHistory.reduce((acc, { current, totalBet, ...rest }) => {
        const updatedBets = omit([ SeatId, ], current);

        return [
          ...acc,
          {
            ...rest,
            current: updatedBets,
            totalBet: getTotalBet(updatedBets),
          },
        ];
      }, []));
      const isCurrentUserSeat = userSeatsIdsSelector(state).includes(SeatId);
      let actions = [ seats.remove({ seatId: SeatId, }), ];

      if (isCurrentUserSeat) {
        actions = [
          ...actions,
          bet.remove([ SeatId, ]),
          history.apply({ history: newHistory, last: last(newHistory).last, }),
          userSeats.clean(),
        ];
      }

      return of(...actions);
    })
  );
}

function startCallBetsEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ START_CALL_BETS, ].includes(MessageType)),
    flatMap(({ MessageType, ...rest }) => {
      const state = store$.value;
      // if insurance is placed, remove it as the dealer does not have blackjack
      const userBets = currentBetsSelector(state);
      const betsWithoutInsurance = map(over(lensProp(MAIN_BET), omit([ INSURANCE_BET, ])))(userBets);
      const actions = [ socket.set({ MessageType, ...rest, }), ];

      if (!equals(userBets, betsWithoutInsurance)) {
        actions.push(bet.apply({ current: betsWithoutInsurance, }));
      }
      return of(...actions);
    })
  );
}

function gameResultEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ GAME_RESULT, GAMERESULTS, ].includes(MessageType)),
    flatMap((socketMessage) => handleGameResult(socketMessage, store$.value))
  );
}

function wonBetsEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WON_SIDE_BETS),
    flatMap((socketMessage) => handleWonBets(socketMessage, store$.value))
  );
}

function balanceEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CURRENT_BALANCE),
    flatMap(() => {
      const state = store$.value;
      const reservedSeatIndex = getGameParams().seatIndex;
      const actions = [];
      if (reservedSeatIndex) {
        const allSeats = nonDealerSeatsSelector(state);
        const { seats, maxSeats, } = playerSeatsSelector(state);
        // playerSeatsSelector takes into consideration ReservedSeat state as well
        const seatsTaken = seats.filter((seat) => seat.hasOwnProperty('allowsBetBehind'));
        const canTakeSeat = length(seatsTaken) < maxSeats;
        const selectedSeat = find(propEq('seatId', `s${reservedSeatIndex}`))(allSeats);

        if (canTakeSeat && selectedSeat && selectedSeat.reservedSeat) {
          actions.push(seat.take({ value: selectedSeat.seatId, }));
        }

        if (!canTakeSeat) {
          actions.push(notification.add({ message: 'messages.maximum_seats_exceeded', }));
        }

        window.history.replaceState(null, '', location.href.replace(`&seatIndex=${reservedSeatIndex}`, ''));
      }
      return of(...actions);
    }),
  );
}

function cardMessageEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD_MESSAGE),
    flatMap((socketMessage) => handleCardMessage(socketMessage))
  );
}

function autoSplitEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === SPLIT_CARDS),
    flatMap((socketMessage) => handleAutoSplit(socketMessage, store$.value))
  );
}

export default combineEpics(
  // socketEpic,
  socketRequestsEpic,
  initialDataEpic,
  gameResultEpic,
  bjConfigEpic,
  handleRoundStatusEpic,
  handleSeatTakenEpic,
  handleSeatLeaveEpic,
  startCallBetsEpic,
  balanceEpic,
  cardMessageEpic,
  autoSplitEpic,
  wonBetsEpic,
);
