
import { createSelector, } from 'reselect';
import {
  isEmpty,
  isNil,
  last,
  path,
  pathOr,
  pipe,
  contains,
  filter,
  pluck,
  not,
  compose,
  prop,
  propEq,
  head,
  allPass,
  lt,
  symmetricDifference,
  complement,
  find,
} from 'ramda';

import {
  CALL_BETS, PLAYER_TURN, CALL_BET_DECISION, NO_MORE_BETS, DEALER_SEAT_ID,
} from '../epics/seats/constants';
import { HIT, SPLIT, DOUBLE, } from '../constants/decision';
import { SEATS, } from '../../constants';
import { SURRENDER, } from '../../components/Insurance/constants';
import { canPlaceBetsSelector, roundStatusSelector, } from './round';
import { isSplitSeat, } from '../../views/GameSeats/utils';
import { clientIdSelector, } from './game';
import { currentBetsSelector, lastBetsSelector, } from './bets';

const { SEAT_TYPES, } = SEATS;
// returns ALL table seats including the dealer seat
export const playersSelector = pathOr({}, [ 'players', ]);
export const seatsSelector = createSelector(
  playersSelector,
  pathOr({}, [ 'seats', ]),
);
export const userCachedSeatsSelector = path([ 'players', 'userSeats', ]);
export const maxNoOfSeatsPerPlayerSelector = pathOr(2, [ 'config', 'bj', 'maxNoOfSeatsPerPlayer', ]);
export const seatsPlayerIdsSelector = createSelector(seatsSelector, pluck('playerId'));

// returns table seats without dealer seat
export const nonDealerSeatsSelector = createSelector(
  // eslint hack
  seatsSelector,
  (seats) => seats.filter((seat) => seat.seatId !== DEALER_SEAT_ID),
);

// returns table free seats
export const freeSeatsSelector = createSelector(
  nonDealerSeatsSelector,
  (nonDealerSeats) => nonDealerSeats.filter((seat) => !seat.taken)
);

// returns dealer seat
export const dealerSeatSelector = createSelector(
  // eslint hack
  seatsSelector,
  (seats) => seats.find((seat) => (seat.seatId === DEALER_SEAT_ID ? seat : false))
);

// returns players seats with cards for user score display
export const tableSeatsWithScoreSelector = createSelector(
  // eslint hack
  nonDealerSeatsSelector,
  (nonDealerSeats) => nonDealerSeats.filter((seat) => !isEmpty(seat.cards))
);

// returns dealer score
export const dealerSeatScoreSelector = createSelector(
  dealerSeatSelector,
  (dealerSeat) => (!isNil(dealerSeat) && !isEmpty(dealerSeat.cards) ? last(dealerSeat.cards).totalCardValues : null)
);

// returns table seats taken by user
export const playerSeatsSelector = createSelector(
  clientIdSelector,
  seatsSelector,
  maxNoOfSeatsPerPlayerSelector,
  (clientId, seats, maxSeats) => ({
    seats: seats.filter((seat) => seat.playerId === clientId),
    maxSeats: parseInt(maxSeats, 10),
  })
);

// returns collection of user seats Ids
export const userSeatsIdsSelector = createSelector(
  seatsSelector,
  clientIdSelector,
  (seats, clientId) => pipe(
    filter((seat) => seat.playerId === clientId),
    pluck('seatId'),
  )(seats),
);

// return collection of user seats objects
export const currentUserSeatedSelector = createSelector(
  clientIdSelector,
  seatsPlayerIdsSelector,
  (clientId, seatsIds) => contains(clientId, seatsIds)
);

export const userCanPlaceBetsSelector = createSelector(
  canPlaceBetsSelector,
  currentUserSeatedSelector,
  (isBetTime, isCurrUserSeated) => isBetTime && isCurrUserSeated
);

// returns table seats with cards taken by user
export const playerSeatsWithCardsSelector = createSelector(
  playerSeatsSelector,
  (playerSeats) => playerSeats.seats.filter((seat) => !isEmpty(seat.cards))
);

// returns seatsIds array of player taken seats
export const playerSeatsIdsSelector = createSelector(
  playerSeatsSelector,
  (playerSeats) => playerSeats.seats.map((seat) => seat.seatId)
);

export const userSeatPropsSelector = createSelector(playerSeatsSelector, ({ seats, maxSeats, }) => ({
  canTakeSeat:
    seats.filter(
      compose(
        not,
        isSplitSeat
      )
    ).length < maxSeats,
  isFirstSeat: !seats.length,
}));

export const playerTurnSelector = createSelector(playersSelector, (players) => players.playerTurn);

// TODO: try to refactor code below using more abstraction
export const userSeatToDisplaySelector = createSelector(
  playerSeatsWithCardsSelector,
  playerTurnSelector,
  playerSeatsIdsSelector,
  (userSeats, playerTurn, playerSeatsIds) => {
    let seatToDisplay = {};
    if (isEmpty(playerTurn) || isNil(playerTurn)) {
      // console.log('CASE1 - no player decision yet so show first seat if any taken');
      seatToDisplay = !isEmpty(userSeats) ? userSeats[0] : {};
    } else if (playerTurn.seatId === DEALER_SEAT_ID) {
      // console.log('CASE2 - dealer turn, show last seat');
      seatToDisplay = last(userSeats);
    } else if (playerSeatsIds.indexOf(playerTurn.seatId) !== -1) {
      // console.log('CASE3 - user turn, show seat cards');
      seatToDisplay = userSeats.find((seat) => seat.seatId === playerTurn.seatId);
    } else {
      // console.log('CASE4 - other playerTurn, show last seat close to the active played one');
      const seats = userSeats.filter((seat) => seat.seatId < playerTurn.seatId);
      seatToDisplay = isNil(seats) || isEmpty(seats) ? userSeats[0] : last(seats);
    }
    return seatToDisplay;
  }
);

// TODO: move magic strings to constants
export const ubjSeatsSelector = createSelector(playerSeatsSelector, ({ seats, }) => seats || []);
export const ubjUserSeatSelector = createSelector(
  ubjSeatsSelector,
  (seats) => seats.find((seat) => seat.seatId === 's1') || {}
);
export const ubjSplitSeatSelector = createSelector(
  ubjSeatsSelector,
  (seats) => seats.find((seat) => seat.seatId === 's1-2') || {}
);


export const callBetsSelector = createSelector(playersSelector, ({ callBets, }) => callBets);

export const callBetsEarlyDecisionSelector = createSelector(
  callBetsSelector,
  nonDealerSeatsSelector,
  ({ seatId, }, seats) => prop('earlyDecision', find(propEq('seatId', seatId))(seats))
);

// return boolean value regarding if there is user's decision turn
export const isUserTurnSelector = createSelector(
  playerTurnSelector,
  userSeatsIdsSelector,
  (playerTurn, userSeatsIds) => (playerTurn && !isEmpty(playerTurn) ? contains(playerTurn.seatId, userSeatsIds) : false)
);

/**
 * Rebet functionality should only be available when player doesn't change seats
 */
export const noRebetSelector = createSelector(
  lastBetsSelector,
  playerSeatsIdsSelector,
  currentBetsSelector,
  userCachedSeatsSelector,
  (lastBets = {}, currentSeats, currentBets, cachedUserSeats) => {
    const equalsIgnoreOrder = compose(isEmpty, symmetricDifference);
    return isEmpty(currentSeats)
      || !isEmpty(currentBets)
      || isEmpty(lastBets.current)
      || !equalsIgnoreOrder(cachedUserSeats, currentSeats);
  }
);

export const earlyDecisionSeatsSelector = createSelector(
  dealerSeatSelector,
  playerSeatsSelector,
  playerTurnSelector,
  ({ cards: dealerCards, }, { seats, }, { seatId, }) => {
    const earlySeats = filter(allPass([
      propEq('earlyDecision', null),
      propEq('callBetDecision', null),
      compose(not, propEq('insuranceDecision', SURRENDER)),
      compose(not, propEq('seatId', seatId)),
      compose(not, propEq('type', SEAT_TYPES.SPLIT_SEAT)),
    ]))(seats);

    return pluck('seatId')(earlySeats.filter(({ cards, }) => cards.length
      && dealerCards.length > 1
      && ![ 'BJ', '21', ].includes(last(cards).totalCardValues)));
  }
);

export const earlyDecisionSeatSelector = createSelector(
  earlyDecisionSeatsSelector,
  playerTurnSelector,
  (seats, { seatId, }) => pipe(
    filter(lt(seatId)),
    head,
  )(seats)
);

export const insuranceStatusSelector = createSelector(
  dealerSeatSelector,
  playerSeatsSelector,
  (dealer, { seats, }) => (!isNil(dealer) && !isEmpty(dealer.cards) ? pipe(
    pluck('cardName'),
    head,
    head,
  )(dealer.cards) === 'A' : null) && pipe(
    pluck('insuranceDecision'),
    filter(complement(isNil)),
    isEmpty,
    not,
  )(seats)
);

export const earlyDecisionStatusSelector = createSelector(
  playerTurnSelector,
  roundStatusSelector,
  insuranceStatusSelector,
  (playerTurn, roundStatus, insuranceStatus) => !isEmpty(playerTurn)
    && [ CALL_BETS, PLAYER_TURN, CALL_BET_DECISION, NO_MORE_BETS, ].includes(roundStatus)
    && !insuranceStatus
);

export const activeSeatIsSplitSelector = createSelector(
  playerTurnSelector,
  playerSeatsSelector,
  ({ seatId, }, { seats, }) => {
    const currentSeat = head(seats.filter((seat) => seat.seatId.includes(seatId)));
    return currentSeat && ![ SPLIT, HIT, DOUBLE, ].includes(currentSeat.callBetDecision);
  },
);
