/* eslint-disable no-shadow */
import { flatMap, pluck, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

import { NO_MORE_BETS, } from '@ezugi/constants';
import insuranceActions from '../../actions/insurance';
import { INSURANCE, } from '../../../components/Insurance/constants';
import {
  userSelector,
  authSelector,
  gameSelector,
  roundSelector,
} from '../../selectors';
import { MAIN_BET, } from '../../../constants/betTypes';

const {
  betActions: { bet, },
  roundActions: { round, },
} = actions;
const { insurance, } = insuranceActions;

const buildInsurancePayload = (state, insuranceCall) => {
  const { operatorId, table_id: tableId, } = authSelector(state);
  const { uid, nickname, currentPlayerToken, } = userSelector(state);
  const gameType = gameSelector(state).serverName;
  const {
    roundId,
    insurance: { seatId, },
  } = roundSelector(state);

  return {
    ClientId: `${operatorId}|${uid}|${tableId}`,
    MessageType: 'InsuranceCall',
    Nickname: nickname,
    destination: 'table',
    gameType,
    TableId: tableId,
    SeatId: seatId,
    InsuranceCallsMessageType: insuranceCall,
    roundId,
    CurrentPlayerToken: currentPlayerToken,
  };
};

const insuranceEpic = (action$, state$) => action$.pipe(
  ofType(insurance.request),
  pluck('payload', 'type'),
  flatMap((type) => {
    const state = state$.value;
    const { amount, seatId, } = state.round.insurance;
    const roundPayload = {
      insurance: { ...state.round.insurance, prompt: false, },
    };

    const actions = [
      insurance.send(buildInsurancePayload(state, type)),
    ];

    /**
       * Update UI with insurance bet when calling INSURANCE
       */
    if (type === INSURANCE) {
      roundPayload.insurance.calledInsurance = true;
      actions.push(
        round.set({
          roundStatus: NO_MORE_BETS,
        }),
        bet.set({
          [seatId]: {
            [MAIN_BET]: {
              index: seatId,
              value: 0,
              insurance: amount,
              valid: true,
            },
          },
        })
      );
    }

    return of(
      round.set(roundPayload),
      ...actions,
    );
  })
);

export default insuranceEpic;
