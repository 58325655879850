import { CALL_BETS_DECISION, CURRENT_BALANCE, } from '@ezugi/constants';
import {
  TAKE_SEAT,
  LEAVE_SEAT,
  SEAT_TAKEN,
} from './store/epics/seats/constants';

import { isBJonURL, } from './store/epics/socket/utils';

import {
  betsEpic,
  betsReducer,
  decisionEpic,
  gameEpic,
  gameReducer,
  insuranceEpic,
  playersEpic,
  playersReducer,
  roundReducer,
  seatsEpic,
  socketEpic,
  splitHand,
  analyticsEpic,
} from './store/index';

import { resultMessageClassName, } from './components/ResultMessage/styles';

// states that will no be affected by the videoDelay
const promptStates = [
  TAKE_SEAT,
  LEAVE_SEAT,
  SEAT_TAKEN,
  CALL_BETS_DECISION,
  CURRENT_BALANCE,
];

export default () => ({
  promptStates,
  components: {
    GameBoard: () => Promise.resolve({ default: () => null, }),
    // ResultMessage: () => Promise.resolve({ default: () => null, }),
    VideoOverlay: () => import('./views/VideoOverlay'),
    Payouts: () => import('./components/Payouts'),
    LeftSideDrawerContent: () => import('./components/CardsStack/GameCardsAndTotalValues'),
    BoardToggle: () => Promise.resolve({ default: () => null, }),
    CallBetsToolbar: () => import('./views/GameWidgets'),
    MobileDecisions: () => import('./views/GameWidgets'),
    Settings: () => import('./components/Widgets/Settings'),
    // ResultDisplay: () => import('./views/GameResults/ResultDisplay'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    GameInfo: () => import('./components/GameInfo'),
  },
  store: {
    epics: [
      socketEpic,
      betsEpic,
      seatsEpic,
      gameEpic,
      playersEpic,
      insuranceEpic,
      decisionEpic,
      splitHand,
      ...(isBJonURL ? [ analyticsEpic, ] : []),
    ],
    reducers: {
      players: playersReducer,
      round: roundReducer,
      bets: betsReducer,
      game: gameReducer,
    },
  },
  props: {
    GameLeftSideDrawer: { style: { top: '-220px', }, },
    GameTools: { trailingNMB: true, },
    ResultMessage: { className: resultMessageClassName, },
  },
  hocs: {
    VideoContainer: () => import('./hocs/VideoContainer'),
  },
  gameBoardHeight: 22,
  desktopBoardToggle: false,
  hideLeftSideDrawer: false,
  landscapeToolbarOrientation: 'vertical',
});
